export const environment = {
  production: true,
  firebaseApi: {
    apiKey: 'AIzaSyB3JHyDcsseRDcU_kAVQMrlb4rM1kFlr4g',
    authDomain: 'cesky-levou-zadni.firebaseapp.com',
    databaseURL: 'https://cesky-levou-zadni-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'cesky-levou-zadni',
    storageBucket: 'cesky-levou-zadni.appspot.com',
    messagingSenderId: '586155226548',
  },
};
